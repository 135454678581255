import { Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';

import { environment } from '@ninety/ui/web/environments';

import { IStorage } from './storage.service';

export enum NinetyCookie {
  userId = 'NINETY-USER-ID',
}

/** Documentation: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie */
export class CookieOptions {
  /** Number of days until the cookies expires or an actual `Date` */
  expires: number | Date = this.getDefaultExpires();
  /** Cookie is only sent to the server when a request is made with the https: scheme (except on localhost) */
  secure = true;
  /** Controls whether a cookie is sent with cross-origin requests, providing some protection against CSRF */
  sameSite: 'Lax' | 'Strict' | 'None' = 'Strict';
  /** A path that must exist in the requested URL, or the browser won't send the Cookie header */
  path = '/';

  constructor(fields?: Partial<CookieOptions>) {
    if (fields) Object.assign(this, fields);
  }

  getDefaultExpires(): Date {
    const date = new Date(); // now
    date.setDate(date.getDate() + 30); // Add 30 days

    return date;
  }
}

const COOKIE_PATH = '/';

@Injectable({
  providedIn: 'root',
})
export class CookieService implements IStorage {
  private isLocalhost = location.hostname === 'localhost';
  private domain = this.isLocalhost
    ? undefined
    : document.location.hostname.includes('90srv.com')
    ? 'qa2.90srv.com'
    : 'ninety.io';

  constructor(private ngxCookieService: NgxCookieService) {}

  get keys(): string[] {
    return Object.keys(this.ngxCookieService.getAll());
  }

  exists(name: string): boolean {
    return this.ngxCookieService.check(name);
  }

  get(name: string): string {
    return this.ngxCookieService.get(name);
  }

  getAndParse<T>(name: string): T {
    const value = this.get(name);

    try {
      // Handles everything except strings
      return JSON.parse(value);
    } catch (e) {
      return value as unknown as T;
    }
  }

  getAll(): { [key: string]: string } {
    return this.ngxCookieService.getAll();
  }

  set(name: string, value: any, opts: CookieOptions = new CookieOptions()): void {
    const _value: string = typeof value === 'string' ? value : JSON.stringify(value) ?? '';

    if (this.isLocalhost) {
      // Browsers can't handle setting domain on localhost & we don't use HTTPS locally
      this.ngxCookieService.set(name, _value, opts.expires, opts.path);
      return;
    }

    const { expires, secure, sameSite, path } = opts;
    this.ngxCookieService.set(name, _value, {
      expires: expires,
      path: path,
      secure: secure,
      sameSite: sameSite,
      ...(this.domain ? { domain: this.domain } : {}),
    });
  }

  delete(name: string): void {
    if (!this.exists(name)) return;

    this.ngxCookieService.delete(name);
  }

  deleteAll(): void {
    this.ngxCookieService.deleteAll(COOKIE_PATH);
  }

  deleteNinetyAll(): void {
    this.ngxCookieService.delete(environment.xsrfCookieName);
    this.ngxCookieService.delete(environment.refreshCookieName);
    this.ngxCookieService.delete(NinetyCookie.userId);
  }

  getRefreshCookie(): string {
    return this.get(environment.refreshCookieName);
  }

  setRefreshCookie(value: string) {
    this.set(environment.refreshCookieName, value);
  }

  getXsrfCookie(): string {
    return this.get(environment.xsrfCookieName);
  }

  setXsrfCookie(value: string) {
    this.set(environment.xsrfCookieName, value);
  }

  getNinetyUserIdCookie(): string {
    return this.get(NinetyCookie.userId);
  }

  setNinetyUserIdCookie(value: string) {
    this.set(NinetyCookie.userId, value);
  }
}
