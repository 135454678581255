import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@ninety/ui/web/environments';

@Injectable()
export class HostInterceptor implements HttpInterceptor {
  apiRegex = /^\/?api\/v4/i;
  dashboardRegex = /^\/?dashboard/i;
  mazApiRegex = /^\/?api\/maz\/v1/i;
  trailingSlashRegex = /\/$|\/(?=\?)|\/(?=#)/;

  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const path = req.url;
    let newOrigin: string;
    if (environment.apiOrigin && this.apiRegex.test(path)) {
      newOrigin = environment.apiOrigin;
    } else if (environment.dashboardOrigin && this.dashboardRegex.test(path)) {
      newOrigin = environment.dashboardOrigin;
    } else if (environment.mazApiOrigin && this.mazApiRegex.test(path)) {
      newOrigin = environment.mazApiOrigin;
    } else if (environment.ngOrigin) {
      newOrigin = environment.ngOrigin;
    }

    if (newOrigin) {
      const newUrl = new URL(path, newOrigin);
      for (const key of req.params.keys()) {
        newUrl.searchParams.set(key, req.params.get(key));
      }
      const normalizedUrl = newUrl.toString().replace(this.trailingSlashRegex, '');
      switch (req.method) {
        case 'DELETE':
        case 'GET':
        case 'HEAD':
        case 'JSONP':
        case 'OPTIONS':
          req = new HttpRequest(req.method, normalizedUrl, {
            headers: req.headers,
            context: req.context,
            reportProgress: req.reportProgress,
            responseType: req.responseType,
            withCredentials: req.withCredentials,
          });
          break;
        case 'POST':
        case 'PUT':
        case 'PATCH':
          req = new HttpRequest(req.method, normalizedUrl, req.body, {
            headers: req.headers,
            context: req.context,
            reportProgress: req.reportProgress,
            responseType: req.responseType,
            withCredentials: req.withCredentials,
          });
          break;
      }
    }

    return next.handle(req);
  }
}
